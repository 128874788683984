import React from "react"
import PropTypes from "prop-types"

import Logo from "../assets/svg/asana_travel_logo.svg"

import  "./layout-screen.scss"

import Header from "../components/header";
import Section from "../components/section";
import Footer from "../components/footer";
// import "./layout.scss";

const Layout = ({ children, type }) => {

    let screenType = 'h100'; // h35
    let wrapStyle = 'boxed';

    return (
      <>
        <div className={`layout ` + type + ` screen ` + screenType} >
            <Header Logo={Logo} wrapStyle={wrapStyle} />
            <Section>
              {children}
            </Section>
        </div>
        <Footer/>  
      </>
    )
  
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
