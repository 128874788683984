import React from "react"

import Layout from "../layouts/layout-screen"
import Wrapper from '../templates/wrapper'
import ContentScreen from '../components/content/content-screen'

import { Helmet } from "react-helmet"

import bg from "../assets/img/bg/calm_water.jpg";

const NotFoundPage = () => {

  let page = {
    content: `
    <h2 style="color: white">404: Page Not Found</h2>
    <p>
      <a href="/" style="color:white; text-decoration: underline">Home Page</a>
    </p>`,
    image: bg
  };

  return (
    <Layout page={page} type="boxed single" source="page" headerStyle="simple">
      <Helmet encodeSpecialCharacters={false}>
          <meta charSet="utf-8" />
          <title>404: Page Not Found</title>
      </Helmet>
      <Wrapper>
        <ContentScreen page={page}/>
      </Wrapper>
      
    </Layout>
  )
}

export default NotFoundPage
