import  React, { useState, useEffect } from "react"
import window from 'global'


const ContentScreen = ({ page, classes }) => {

    const { width, height } = useViewport();

     
    let bg = page.featuredImage ? findImage2Width( page.featuredImage.node, width, height ) : page.image ? page.image : null;
    let styles = { backgroundImage: `url(${bg})` }
    let _class = bg ? 'bg ' + page.slug : page.slug;

    return (
        <section className={`content-wrap ${_class} ${classes}`} style={styles}>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div 
                            className="content"
                            dangerouslySetInnerHTML={{ __html: page.content }}
                        ></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContentScreen


const findImage2Width = ( image, ww, wh ) => { 

    let sizes = image.mediaDetails.sizes;
        sizes = sizes.sort((a, b) => parseFloat(b.width) - parseFloat(a.width));
    let found = image.sourceUrl;
    let quality = 0.95;

    for( let size of sizes) {
        if( size.width > ww  * quality && size.height > wh * quality ) {
            found = size.sourceUrl;
        }
    }

    return found;
}

const getWidth = () => window.innerWidth || 500;

const getHeight = () => window.innerHeight || 800;


const useViewport = () => {
    const [width, setWidth] = useState(getWidth());
    const [height, setHeight] = useState(getHeight());
  
    useEffect(() => {
      const handleWindowResize = () => {
        setWidth(getWidth());
        setHeight(getHeight());
      }
  
      window.addEventListener("resize", handleWindowResize);
      return () => window.removeEventListener("resize", handleWindowResize);
    }, []);


    return { width, height };
}
